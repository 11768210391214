import './main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';

// assets referenced for webpack
import approachImagePath from '../assets/images/approach.jpg';
import bacpImagePath from '../assets/images/bacp.png';
import brandLogoImagePath from '../assets/images/brand-logo.jpg';
import hannahImagePath from '../assets/images/hannah.jpg';
import heverImagePath from '../assets/images/hever.png';
import locationImagePath from '../assets/images/location.jpg';
import splashImagePath from '../assets/images/splash.jpg';


var imagePaths = {
    approachImage: approachImagePath,
    bacpImage: bacpImagePath,
    brandLogoImage: brandLogoImagePath,
    hannahImage: hannahImagePath,
    heverImage: heverImagePath,
    locationImage: locationImagePath,
    splashImage: splashImagePath
};
Elm.Main.init({ flags: imagePaths });

serviceWorker.unregister();
